import { Grid } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import CustomModal from 'src/components/CustomModal';
import { Consultation } from 'src/interfaces/interfaces';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { NameAndDuration } from './NameAndDuration';
import { toast } from 'react-toastify';
import { AddConsultation, valideAddConsultationForm } from './Logic';
import {  baseScreenHeight } from 'src/utils/constants/baseStyles';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import EventRoomAndRemark from 'src/views/Settings/Consultations/ConsultationsForm/EventRoomAndRemark';
import ConsultationTypeForm from 'src/views/Settings/Consultations/ConsultationsForm/ConsultationTypeForm';
import { postConsultations,editConsultations } from 'src/services/Consultations';
import ConsultationIntervenant from 'src/views/Settings/Consultations/ConsultationsForm/ConsultationIntervenant';

interface ConsultationFormProps {
    forAdmin?: boolean;
    modalOpen: boolean;
    modalToggle: Function;
    dayHospitalId?: number;
    setUpdateList?: any;
    consultationData?: any;
    consultData?: Consultation;
    forProgram?: boolean;
    programId?: number;
}

export const defaultConsultationValue: Consultation = {
    name: '',
    duration: '',
    consultationTypeId: 1,
    remark: '',
    roomId: 0,
    attendees: [],
};

export const ConsultationForm: FC<ConsultationFormProps> = (props) => {
    const {
        modalOpen,
        modalToggle,
        setUpdateList,
        consultationData,
        consultData,
        forAdmin,
    } = props;
    const [savingData, setSavingData] = useState<boolean>(false);
    const [lockConsultationInput, setLockConsultationInput] = useState<boolean>(false);
    const [payload, setPayload] = useState<Consultation>({
        ...defaultConsultationValue
    });

    const isVisio = payload?.consultationTypeId === 2;
    const loadingData = <CustomCircleLoader height={25} width={25} />;
    const specialityId = -1;
    const successfull = (message: string) => {
        setSavingData(false);
        setLockConsultationInput(false);
        setUpdateList((prev: any) => !prev);
        setPayload(defaultConsultationValue);
        modalToggle();
        toast.success(message);
    };

    const failed = (message: string) => {
        setSavingData(false);
        setLockConsultationInput(false);
        toast.error(message);
    };

    const performConsultation = () => {
        const finalPayload = { ...payload };
        if(consultData?.id){
            setSavingData(true);
            editConsultations(consultData?.id,finalPayload)
            .then((response) => {
                successfull('Consultation modifié avec succès');
            })
            .catch(() => {
                failed("Erreur de modification de la consultation");
            });
        }else{
            setSavingData(true)
            postConsultations(finalPayload)
            .then((response) => {
                successfull('Consultation ajouté avec succès');
            })
            .catch(() => {
                failed("Erreur d'ajout de la consultation");
            });
        }
    }

    useEffect(() => {
        consultData && forAdmin && setPayload(consultData);
    }, [consultData, forAdmin]);

    const toggleMainModal = () => {
        modalToggle(false);
        setPayload(defaultConsultationValue);
    };
    
    console.log(consultData,"consultDataaaa");
    return (
        <CustomModal
            withMinHeight
            height={baseScreenHeight.newEventModal}
            width={600}
            title={consultData?.id! > 0 ? "Modifier la consultation" : 'Nouvelle consultation'}
            toggleModal={toggleMainModal}
            open={modalOpen}
            footer={
                <Grid container flexDirection="row" justifyContent="center" alignItems="center">
                    <CustomLoadingButton
                        loading={savingData}
                        disabled={!valideAddConsultationForm(payload as AddConsultation) || savingData}
                        handleClick={() => performConsultation() }
                        width={"80%"}
                        label={consultData?.id ? "Modifier consultation" : "Ajouter consultation"}
                    />
                </Grid>
            }
        >
            <NameAndDuration
                loadingData={loadingData}
                lockConsultationInput={lockConsultationInput}
                setPayload={setPayload}
                payload={payload}
                consultData={consultData}
            />

            <ConsultationIntervenant
                loadingData={loadingData}
                defaultSpecialityId={specialityId}
                consultData={consultData}
                setPayload={setPayload}
            />

            <ConsultationTypeForm
                loadingData={loadingData}
                setConsultation={setPayload}
                consultationData={consultationData}
                consultData={consultData}
            />

            <EventRoomAndRemark
                isVisio={isVisio}
                loadingData={loadingData}
                setConsultation={setPayload}
                consultationData={consultationData}
            />
        </CustomModal>
    );
};

import { Grid, Box } from '@mui/material';
import { FC, useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { CustomText } from 'src/components/Text/CustomText';
import { NewHDJ } from 'src/interfaces/interfaces';
import HdjDataService from 'src/services/Hdj/dayHospital.service';
import { baseFontFamily } from 'src/utils/constants/baseStyles';
import HdjFormContent from './HdjFormContent';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveDayHospitalById } from 'src/actions/dayHospitals/dayHospitals';
import HdjFormHeader from './HdjFormHeader';
import CustomCircleLoader from 'src/components/CustomLoader/CustomCircleLoader';
import { HdjContext } from 'src/providers/HdjProvider';
import CustomLoadingButton from 'src/components/Button/CustomLoadingButton';
import { ROUTES } from 'src/routes/routes';
import useStyles, { sxStyles } from './styles';
import { toast } from 'react-toastify';

const defaultHDJValue = {
    icon: '',
    name: '',
    consultationCount: 1,
    hourCount: 1,
    workshopIds: [],
    consultationIds: [],
    documentIds: [],
    coordinatorId: 0,
};

const HDJForm: FC = (props) => {
    const [loadingHdjData, setLoadingHdjData] = useState(false);
    const [hdjPayload, setHdjPayload] = useState<NewHDJ>(defaultHDJValue);
    const params = useParams<any>();
    const classes = useStyles();
    const history = useHistory();
    const location: any = useLocation();
    const [saving, setSaving] = useState(false);
    const dispatch = useDispatch();
    const { currentHdjInfos, setCurrentHdjInfos } = useContext(HdjContext);
    const hdjId: number = params?.id ? +params.id : 0;
    const handleFectHdjData = () => dispatch(retrieveDayHospitalById(setLoadingHdjData, hdjId, setHdjPayload));
    const createForm = location?.state?.newForm;
    const redirectToNewList = () => history.push(ROUTES.hdj);

    const fetchedHdjData = useSelector((state: any) => {
        const hdjData = hdjId > 0 ? state?.hdj?.data : null;
        return hdjData;
    });

    const cleanFormInput = () => {
        setCurrentHdjInfos(null);
        setHdjPayload(defaultHDJValue);
    };

    useEffect(() => {
        if (hdjId > 0 && !createForm) {
            handleFectHdjData();
        }

        return () => {
            cleanFormInput();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hdjId]);

    // useProtectedPageRedirection();

    const successfull = (message: string) => {
        toast.success(message);
        setSaving(false);
        cleanFormInput();
        redirectToNewList();
    };

    const failed = (message: string) => {
        toast.error(message);
        setSaving(false);
    };

    const handleClick = () => {
        setSaving(true);
        if (hdjId > 0) {
            HdjDataService.update(hdjId, hdjPayload)
                .then(() => successfull('Hdj modifié avec succès'))
                .catch((e) => failed("Erreur de modification d'Hdj"));
        } else {
            HdjDataService.create(hdjPayload)
                .then(() => successfull('Hdj créé avec succès'))
                .catch(() => failed("Erreur de création d'hdj"));
        }
    };

    const hdjData = !createForm ? currentHdjInfos ?? fetchedHdjData ?? null : null;
    const canSubmit = hdjPayload?.name?.trim()?.length === 0 || hdjPayload?.coordinatorId === 0;

    return (
        <Box className={classes.container}>
            <CustomText
                style={{ textAlign: 'center', paddingTop: 5 }}
                fontFamily={baseFontFamily.BreeSerif}
                fontSize={18}
            >
                Hospitalisation de jour
            </CustomText>
            <Box sx={sxStyles.scrollContainer}>
                <HdjFormHeader  hdjIcon={hdjData?.icon} setHdj={setHdjPayload} />

                {!currentHdjInfos && loadingHdjData ? (
                    <Box sx={sxStyles.container}>
                        <CustomCircleLoader />
                    </Box>
                ) : (
                    <>
                        <HdjFormContent createForm={createForm} fetchedHdjData={hdjData} setHdj={setHdjPayload} hdj={hdjPayload} />

                        <Grid container justifyContent="center" style={{ marginTop: 25 }}>
                            <CustomLoadingButton
                                disabled={canSubmit}
                                loading={saving}
                                handleClick={handleClick}
                                label={"Enregistrer l'HDJ"}
                            />
                        </Grid>
                    </>
                )}
            </Box>
        </Box>
    );
};

export default HDJForm;

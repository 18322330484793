import React, { useState, FC, Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

type HdjType = {
    loadingHdjList: boolean;
    setLoadingHdjList: Dispatch<SetStateAction<boolean>>;
    hdjList: null | [];
    setHdjList: (val: any) => void;
    loadingPlanningList: boolean;
    setLoadingPlanningList: (val: boolean) => void;
    planningFilters: null | [];
    setPlanningFilters: (val: any) => void;
    specialityFilterId: number;
    setSpecialityFilterId: (val: number) => void;
    consultationFilters: null | [];
    setConsultationFilters: (val: any) => void;
    consultationEventTypeId: number;
    setConsultationEventTypeId: (val: number) => void;
    dayHospitalIds: number[];
    setDayHospitalIds: (val: number[]) => void;
    currentHdjInfos: any;
    setCurrentHdjInfos: (val: any) => void;
    isCombinedView: boolean;
    setIsCombinedView: (val: boolean) => void;
    showPlanningBy: 'speciality' | 'room';
    setShowPlanningBy: (val: 'speciality' | 'room') => void;
    combinedIds: [];
    setCombinedIds: (val: any) => void;
    checkedHdjWithCombinedView: [];
    setCheckedHdjWithCombinedView: (val: any) => void;
    searchQuery: string;
    setSearchQuery: (val: string) => void;
    hourCount:string;
    setHourCount: (val:string) => void;
};

export const HdjContext = createContext<HdjType>({
    loadingHdjList: false,
    setLoadingHdjList: () => {},
    hdjList: [],
    setHdjList: () => {},
    loadingPlanningList: false,
    setLoadingPlanningList: () => {},
    planningFilters: [],
    setPlanningFilters: () => {},
    specialityFilterId: 0,
    setSpecialityFilterId: () => {},
    consultationFilters: [],
    setConsultationFilters: () => {},
    consultationEventTypeId: 0,
    setConsultationEventTypeId: () => {},
    dayHospitalIds: [],
    setDayHospitalIds: () => {},
    currentHdjInfos: null,
    setCurrentHdjInfos: () => {},
    isCombinedView: false,
    setIsCombinedView: () => {},
    showPlanningBy: 'speciality',
    setShowPlanningBy: () => {},
    combinedIds: [],
    setCombinedIds: () => {},

    checkedHdjWithCombinedView: [],
    setCheckedHdjWithCombinedView: () => {},

    searchQuery: '',
    setSearchQuery: () => {},

    hourCount :'00:00',
    setHourCount : () => {}
});

const HdjProvider: FC<any> = (props) => {
    const { children } = props;
    const [loadingHdjList, setLoadingHdjList] = useState<boolean>(false);
    const [hdjList, setHdjList] = useState<any>([]);
    const [loadingPlanningList, setLoadingPlanningList] = useState<boolean>(false);
    const [planningFilters, setPlanningFilters] = useState<any>([]);
    const [specialityFilterId, setSpecialityFilterId] = useState<number>(0);
    const [consultationFilters, setConsultationFilters] = useState<any>([]);
    const [dayHospitalIds, setDayHospitalIds] = useState<any>([0]);
    const [consultationEventTypeId, setConsultationEventTypeId] = useState<number>(0);
    const [currentHdjInfos, setCurrentHdjInfos] = useState<any>(null);
    const [isCombinedView, setIsCombinedView] = useState<boolean>(false);
    const [showPlanningBy, setShowPlanningBy] = useState<'speciality' | 'room'>('speciality');
    const [combinedIds, setCombinedIds] = useState<any>([]);
    const [checkedHdjWithCombinedView, setCheckedHdjWithCombinedView] = useState<any>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [hourCount,setHourCount] = useState<string>('00:00');

    return (
        <HdjContext.Provider
            value={{
                loadingHdjList,
                setLoadingHdjList,

                hdjList,
                setHdjList,

                loadingPlanningList,
                setLoadingPlanningList,

                planningFilters,
                setPlanningFilters,

                specialityFilterId,
                setSpecialityFilterId,

                consultationFilters,
                setConsultationFilters,

                consultationEventTypeId,
                setConsultationEventTypeId,

                dayHospitalIds,
                setDayHospitalIds,

                currentHdjInfos,
                setCurrentHdjInfos,

                isCombinedView,
                setIsCombinedView,

                showPlanningBy,
                setShowPlanningBy,

                combinedIds,
                setCombinedIds,

                checkedHdjWithCombinedView,
                setCheckedHdjWithCombinedView,

                searchQuery,
                setSearchQuery,

                hourCount,
                setHourCount
            }}
        >
            {children}
        </HdjContext.Provider>
    );
};

export default HdjProvider;
